exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-guides-jsx": () => import("./../../../src/pages/guides.jsx" /* webpackChunkName: "component---src-pages-guides-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pensions-glossary-jsx": () => import("./../../../src/pages/pensions-glossary.jsx" /* webpackChunkName: "component---src-pages-pensions-glossary-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/BlogPostTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-conversion-page-template-jsx": () => import("./../../../src/templates/ConversionPageTemplate.jsx" /* webpackChunkName: "component---src-templates-conversion-page-template-jsx" */),
  "component---src-templates-glossary-entry-template-jsx": () => import("./../../../src/templates/GlossaryEntryTemplate.jsx" /* webpackChunkName: "component---src-templates-glossary-entry-template-jsx" */),
  "component---src-templates-guide-template-jsx": () => import("./../../../src/templates/GuideTemplate.jsx" /* webpackChunkName: "component---src-templates-guide-template-jsx" */),
  "component---src-templates-service-page-template-jsx": () => import("./../../../src/templates/ServicePageTemplate.jsx" /* webpackChunkName: "component---src-templates-service-page-template-jsx" */)
}

